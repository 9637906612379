<template>
    <div>
        <div class="card p-2 pb-3 pl-3 pr-3 mb-3">
            <div class="text-center mb-3">
                <h4 class="mb-0"><strong>Top 10 country have the most revenue</strong></h4>
                <div>{{timerangeSubTitle}}</div>
            </div>
            
            <div class="mt-2 d-md-flex justify-content-center">
                <div class="mr-md-3 mb-2 mb-md-0">
                    <el-date-picker v-model="requestParams.dateRange"
                                    size="small"
                                    :format="$elDatePickerFormat" type="daterange"
                                    @change="debounceFnc"
                                    value-format="yyyy-MM-dd"
                                    range-separator="To"
                                    start-placeholder="Start date"
                                    end-placeholder="End date">
                    </el-date-picker>
                </div>
                <div class="mb-3 mb-md-0">
                    <button class="btn btn-sm btn-primary" @click="getData">
                        <i class="fas fa-sync-alt mr-2"></i> Refresh
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="reset">
                        All time
                    </button>
                </div>
                <div class="d-block d-md-none">
                    <el-dropdown @command="handleCommand"  trigger="click">
                        <el-button type="primary">
                            {{timerangeSubTitle}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="{ execute: lastNDay, param: 30 }">Last 30 days</el-dropdown-item>
                            <el-dropdown-item :command="{ execute: lastNDay, param: 60 }">Last 60 days</el-dropdown-item>
                            <el-dropdown-item :command="{ execute: thisMonth }">This month</el-dropdown-item>
                            <el-dropdown-item :command="{ execute: lastMonth }">Last month</el-dropdown-item>
                            <el-dropdown-item :command="{ execute: lastNMonth, param: 5 }">Last 6 months</el-dropdown-item>
                            <el-dropdown-item :command="{ execute: thisWeek }">This week</el-dropdown-item>
                            <el-dropdown-item :command="{ execute: lastWeek }">Last week</el-dropdown-item>
                            <el-dropdown-item :command="{ execute: lastNWeek, param: 2 }">Last 3 weeks</el-dropdown-item>
                            <el-dropdown-item :command="{ execute: lastNWeek, param: 5 }">Last 6 weeks</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="mb-3 mb-md-0 ml-md-3 d-none d-md-block">
                    <button class="btn btn-sm btn-primary ml-1" @click="lastNDay(30)">
                        Last 30 days
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="lastNDay(60)">
                        Last 60 days
                    </button>
                </div>
                <div class="mb-3 mb-md-0 ml-md-3 d-none d-md-block">
                    <button class="btn btn-sm btn-primary ml-1" @click="thisMonth">
                        This month
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="lastMonth">
                        Last month
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="lastNMonth(2)">
                        Last 3 months
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="lastNMonth(5)">
                        Last 6 months
                    </button>
                </div>
                <div class="mb-3 mb-md-0 ml-md-3 d-none d-md-block">
                    <button class="btn btn-sm btn-primary ml-1" @click="thisWeek">
                        This week
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="lastWeek">
                        Last week
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="lastNWeek(2)">
                        Last 3 weeks
                    </button>
                    <button class="btn btn-sm btn-primary ml-1" @click="lastNWeek(5)">
                        Last 6 weeks
                    </button>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6 col-sm-6 col-md-20percent"
                     v-for="(countryItem, indexCountryItem) in listData" :key="countryItem.ContryCode">
                    <div class="info-box mb-0 d-flex flex-column flex-md-row">
                        <span class="info-box-icon mb-2 mb-md-0">
                            <img :src="`/flag/${countryItem.ContryCode}.svg`" style="height: 2.5rem;">
                        </span>
                        <div class="info-box-content mb-0" style="line-height: 1.3rem;">
                            <span class="info-box-text mt-0">{{indexCountryItem+1}}. {{countryItem.CountryName}}</span>
                            <span class="info-box-number mt-0">
                                {{countryItem.UserQuantity}} users
                            </span>
                            <span class="info-box-number mt-0">
                                ${{countryItem.TotalAmount | numFormat('0,0[.][00]')}}
                            </span>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import chartDataApi from '@/api/common/chartData';
    import countryList from "@/constant/countryList";
    var numeral = require('numeral');
    import { debounce } from 'vue-debounce';
    import moment from 'moment';
    export default {
        data() {
            return {
                debounceFnc: debounce(() => {
                    
                }, 300),
                isLoading: false,
                countryList: countryList,
                listData: [],
                requestParams: {
                    dateRange: []
                },
                timerangeSubTitle: ''
            };
        },
        mounted() {
            this.lastNDay(30);
            this.debounceFnc = debounce(() => {
                this.timerangeSubTitle = `From ${this.requestParams.dateRange[0]} to ${this.requestParams.dateRange[1]}`;
                this.getData();
            }, 300);
        },
        methods: {
            handleCommand(command) {
                try{
                    if(command.execute !== undefined){
                        if(command.param !== undefined) command.execute(command.param);
                        else command.execute();
                    } 
                }catch (error) {
                    console.log(error)
                }
            },
            getData() {
                this.isLoading = true;
                let requestData = { startTime: null, endTime: null };
                if (this.requestParams.dateRange.length > 0) {
                    requestData = { startTime: this.requestParams.dateRange[0], endTime: this.requestParams.dateRange[1] };
                }
                chartDataApi.getUserByTop10Country(requestData).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.listData = response.data.data;
                        this.isLoading = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoading = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoading = false;
                });
            },
            reset() {
                this.requestParams.dateRange = [];
                this.timerangeSubTitle = 'From the beginning till now';
                this.getData();
            },

            lastNDay(numberOfLast) {
                this.requestParams.dateRange = [moment().subtract(numberOfLast, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last ${numberOfLast} days, ${this.requestParams.dateRange[0]} to ${this.requestParams.dateRange[1]}`;
                this.getData();
            },

            thisMonth() {
                this.requestParams.dateRange = [moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `This month, ${this.requestParams.dateRange[0]} to ${this.requestParams.dateRange[1]}`;
                this.getData();
            },
            lastMonth() {
                this.requestParams.dateRange = [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last month, ${this.requestParams.dateRange[0]} to ${this.requestParams.dateRange[1]}`;
                this.getData();
            },
            lastNMonth(numberOfLast = 1) {
                this.requestParams.dateRange = [moment().subtract(numberOfLast, 'months').startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last ${numberOfLast} month, ${this.requestParams.dateRange[0]} to ${this.requestParams.dateRange[1]}`;
                this.getData();
            },


            thisWeek() {
                this.requestParams.dateRange = [moment().startOf('weeks').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `This week, ${this.requestParams.dateRange[0]} to ${this.requestParams.dateRange[1]}`;
                this.getData();
            },
            lastWeek() {
                this.requestParams.dateRange = [moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'), moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last week, ${this.requestParams.dateRange[0]} to ${this.requestParams.dateRange[1]}`;
                this.getData();
            },
            lastNWeek(numberOfLast = 1) {
                this.requestParams.dateRange = [moment().subtract(numberOfLast, 'weeks').startOf('week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
                this.timerangeSubTitle = `Last ${numberOfLast} week, ${this.requestParams.dateRange[0]} to ${this.requestParams.dateRange[1]}`;
                this.getData();
            },
            customTime() {

            }
        }
    }
</script>

<style scoped>
    @media (min-width: 960px) {
        .col-md-20percent {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
</style>